import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, Button, Container, Typography } from '@mui/material';
import HomeLayout from './HomeLayout';

const StatusPageContent = ({ subText, title, text, type }) => {
  const navigate = useNavigate();
  return (
    <HomeLayout>
      {' '}
      <Container sx={{}}>
        <Box sx={{ px: 2, mb: 10, mt: 4, paddingTop: 4, background: '#fff', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant={'h4'} sx={{ fontWeight: '500 !important', color: `${type}.main` }}>
            {title}
          </Typography>
          <Typography sx={{ mt: 3, textAlign: 'center' }}>{text}</Typography>
          <Typography sx={{ mt: 0.5, mb: 2, textAlign: 'center' }}>{subText}</Typography>
          <Button
            onClick={() => {
              navigate('/');
            }}
            size="large"
            variant="outlined"
            sx={{
              color: 'rgba(0,0,0,0.4)',
              borderColor: 'rgba(0,0,0,0.4)',
              maxWidth: '150px',
              width: '100%',
              mb: 3,
              '&:hover': {
                borderColor: 'rgba(0,0,0,0.45)',
                backgroundColor: 'rgba(0,0,0,0.02)',
              },
            }}>
            Закрыть
          </Button>
        </Box>
      </Container>
    </HomeLayout>
  );
};

export default StatusPageContent;
