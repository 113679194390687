import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import StatusPageContent from '../components/StatusPageContent';

const SuccessPage = () => {
  return <StatusPageContent title="Успешно!" text={'Ваш платеж выполнен! Баланс личного кабинета пополнен.'} subText={'Теперь его можно тратить на донат'} type="success" />;
};

export default SuccessPage;
