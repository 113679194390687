import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { locales } from '../locales';
import clsx from 'clsx';
import '../styles/GameButton.scss';
const GameButton = ({ onClick, text, className = 'bubbly-button', style = {} }) => {
  const navigate = useNavigate();
  const [showAnimate, setShowAnimate] = useState(false);
  return (
    <Box
      className={clsx(className, showAnimate && 'animate')}
      component={'button'}
      sx={{
        display: { mobile: 'block', xs: 'none' },
        transition: 'all 0.3s',
        fontSize: '16px',
        border: 'none',
        outline: 'none',
        background: '#181818',
        color: 'rgb(237, 188, 11)',
        padding: '0 15px',
        height: '34px',
        borderRadius: '6px',
        '&:hover': {
          background: '#333',
        },
        ...style,
      }}
      onClick={() => {
        setShowAnimate(true);
        setTimeout(() => {
          setShowAnimate(false);
          onClick();
        }, 500);
      }}>
      {text}
    </Box>
  );
};

export default GameButton;
