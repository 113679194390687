import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import '../styles/ListGame.scss';
import ItemGame from './ItemGame';
const ListGame = () => {
  const listGame = [
    { img: '/marvel-rivals.jpg', name: 'Marvel rivals', link: 'marvel-rivals' },
    { img: '/arena-breakout-infinite.jpg', name: 'Arena Breakout: Infinite', link: 'arena-breakout-infinite' },
	{ img: '/genshin-impact.jpg', name: 'Genshin Impact', link: 'genshin-impact' },
    { img: '/honkai_star_rail.png', name: 'Honkai: Star Rail', link: 'honkai-star-rail' },
	{ img: '/zenless-zone-zero.jpg', name: 'Zenless Zone Zero', link: 'zenless-zone-zero' },
	{ img: '/identityv.jpg', name: 'Identity V', link: 'identity-v' },
	/*{ img: '/wuthering_waves.jpg', name: 'Скоро!', link: '' },*/
    { img: '/mobile-legends.jpg', name: 'Mobile legends', link: 'mobile-legends' },
	{ img: '/honor_of_kings.jpg', name: 'Honor of Kings', link: 'honor-of-kings' },
	{ img: '/tower_of_fantasy.jpg', name: 'Tower of Fantasy', link: 'tower-of-fantasy' },
	{ img: '/pubg-mobile.jpg', name: 'PUBG Mobile (Global)', link: 'pubg-mobile-global' },
	{ img: '/arena-breakout.jpg', name: 'Arena Breakout', link: 'arena-breakout' },
	{ img: '/blood_strike.jpg', name: 'Blood Strike', link: 'blood-strike' },
	{ img: '/magic-awakened.jpg', name: 'HP Magic Awakened', link: 'hp-magic-awakened' },	
    { img: '/onmyoji-arena.jpg', name: 'Onmyoji Arena', link: 'onmyoji-arena' },
	/*{ img: '/brawl-stars.jpg', name: 'Brawl Stars', link: 'brawl-stars'  },*/
    /* { img: '/clash-royale.jpg', name: 'Clash Royale', link: 'clash-royale'  },*/
    /*{ img: '/clash-of-clans.jpg', name: 'Clash of Clans', link: 'clash-of-clans'  },*/
    { img: '/dbd-mobile.jpg', name: 'Dead by Daylight Mobile', link: 'dbd-mobile' },
	{ img: '/farlight_84.jpg', name: 'Farlight 84', link: 'farlight-84' },
	{ img: '/teamfight_tactics.jpg', name: 'Teamfight Tactics', link: 'teamfight-tactics' },
    { img: '/lotr-risetowar.jpg', name: 'LOTR: Rise to War', link: 'lotr-rise-to-war' },
    { img: '/life-after.jpg', name: 'Life After', link: 'life-after' },
    { img: '/knives-out.jpg', name: 'Knives Out', link: 'knives-out' },
    { img: '/ace-racer.jpg', name: 'Ace Racer', link: 'ace-racer' },
    { img: '/infinite-lagrange.jpg', name: 'Infinite Lagrange', link: 'infinite-lagrange' },
	{ img: '/eve_echoes.jpg', name: 'Eve echoes', link: 'eve-echoes' },
  ];
  return (
    <>
      <div className="list-game">
        {listGame?.map((item) => (
          <ItemGame {...item} />
        ))}
      </div>
    </>
  );
};

export default ListGame;
