import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import StatusPageContent from '../components/StatusPageContent';

const ErrorPage = () => {
  return <StatusPageContent title="Ошибка!" text={'Что-то пошло не так.'} subText={'Попробуйте ещё раз или напишите в поддержку сайта.'} type="error" />;
};

export default ErrorPage;
